@font-face {
  font-family: "SpoqaHanSansNeo";
  src: url(./assets/fonts/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Medium.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1200px) {
    height: 100vh;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
